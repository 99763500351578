import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { toRefs, computed, unref } from 'vue';
export default {
  __name: 'StatementReport',
  props: {
    testingResultDimension: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const {
      testingResultDimension
    } = toRefs(props);
    const resultTableHeader = computed(() => {
      const {
        reportHead = []
      } = unref(testingResultDimension);
      const reporHeaders = reportHead.reduce((list, item) => {
        if (item === 'correctScoringItemScore') {
          list.push({
            title: '修正值',
            dataIndex: 'correctScoringItemScore',
            ellipsis: true,
            width: 100,
            key: 'correctScoringItemScore'
          });
        } else if (item === 'brief') {
          list.push({
            title: '简要说明',
            dataIndex: 'brief',
            ellipsis: true,
            width: 100,
            key: 'brief'
          });
        } else if (item === 'standardScoringItemScore') {
          list.push({
            title: '标准分',
            dataIndex: 'standardScoringItemScore',
            ellipsis: true,
            width: 100,
            key: 'standardScoringItemScore'
          });
        } else if (item === 'norm') {
          list.push({
            title: '常模',
            dataIndex: 'norm',
            width: 70,
            key: 'norm'
          });
        }
        return list;
      }, []);
      const headers = [{
        title: '因子名称',
        dataIndex: 'dimensionName',
        width: 120,
        key: 'dimensionName'
      }, {
        title: '原始值',
        dataIndex: 'originalScoringItemScore',
        width: 80,
        key: 'originalScoringItemScore'
      }];
      return [...headers, ...reporHeaders];
    });
    const reulstData = computed(() => {
      const {
        reportScoreList = []
      } = unref(testingResultDimension);
      return reportScoreList;
    });
    return (_ctx, _cache) => {
      const _component_a_table = _resolveComponent("a-table");
      return _openBlock(), _createBlock(_component_a_table, {
        class: "mrl10",
        columns: _unref(resultTableHeader),
        bordered: "",
        "data-source": _unref(reulstData),
        pagination: false,
        "row-class-name": (_record, index) => index % 2 === 1 ? 'table-striped' : null,
        scroll: {
          x: '100%',
          y: 200
        }
      }, null, 8, ["columns", "data-source", "row-class-name"]);
    };
  }
};