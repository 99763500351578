const basicRoutes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/index.vue'),
		meta: { role: 'all' }
	},
	{
		path:'/screen',
		name:'大屏管理',
		component:()=>import("@/views/screenManage/index.vue"),
		meta: { role: '/screen' }
	},
	{
		path: "/home",
		name: 'home',
		component: () => import('@/views/home/index.vue'),
		meta: { role: 'all' }
	},
	{
		path: "/noPermission",
		name: 'noPermission',
		component: () => import('@/views/error/noPermission.vue'),
		meta: { role: 'all' }
	},
	{
		path: "/console",
		name: 'console',
		component: () => import('@/views/console/index.vue'),
		meta: { role: 'all' },
		children:[
			{
				path:'/generalView',
				name:'总览',
				component:()=>import("@/views/console/generalView/index.vue"),
				meta: { role: '/generalview' }
			},
			{
				path:'/organizationChart',
				name:'组织架构',
				component:()=>import("@/views/console/organizationChart/index.vue"),
				meta: { role: '/organizationChart' }
			},
			{
				path:'/config',
				name:'app配置',
				component:()=>import("@/views/console/appConfigManage/index.vue"),
				meta: { role: '' }
			},
			{
				path:'/basicManage',
				name:'基础配置',
				component:()=>import("@/views/console/basicManage/basicConfig.vue"),
				meta: { role: '/basicmanage' }
			},
			{
				path:'/guide',
				name:'引导页配置',
				component:()=>import("@/views/console/basicManage/guide.vue"),
				meta: { role: '/guide' }
			},
			{
				path:'/bannerManage',
				name:'Banner配置',
				component:()=>import("@/views/console/basicManage/bannerManage.vue"),
				meta: { role: '/bannermanage' }
			},
			{
				path: '/suggestFeedback',
				name: '建议与反馈',
				component: ()=>import("@/views/console/basicManage/suggestFeedback.vue"),
				meta: { role: '/suggestfeedback' }
			},
			{
				path:'/scaleDesign',
				name:'量表设计',
				component:() => import("@/views/console/testManage/scaleDesign.vue"),
				meta: { role: '/scaleDesign' }
			},
			{
				path: '/scaleResult',
				name: '量表结果',
				component: () => import("@/views/console/testManage/scaleResult.vue"),
				meta: { role: '/scaleResult' }
			},
			{
				path:'/questionAndHomeConfig',
				name:'题目&规则配置',
				component:() => import("@/views/console/homePageConfiguration/questionAndHomeConfig.vue"),
				meta: { role: '/questionAndHomeConfig' }
			},
			{
				path:'/temperamentResult',
				name:'气质速测结果',
				component:() => import("@/views/console/homePageConfiguration/temperamentResult.vue"),
				meta: { role: '/temperamentResult' }
			},
			{
				path:'/eventCalendarConfig',
				name:'活动日历配置',
				component:() => import("@/views/console/homePageConfiguration/eventCalendarConfig.vue"),
				meta: { role: '/eventCalendarConfig' }
			},
			{
				path:'/moodRecording',
				name:'心情日记记录',
				component:() => import("@/views/console/homePageConfiguration/moodRecording.vue"),
				meta: { role: '/moodRecording' }
			},
			{
				path:'/sickInfoNameList',
				name:'病人信息名单',
				component:() => import("@/views/console/severeMental/sickInfoNameList.vue"),
				meta: { role: '/sickInfoNameList' }
			},
			{
				path:'/followUpStatistics',
				name:'回访数据统计',
				component:() => import("@/views/console/severeMental/followUpStatistics.vue"),
				meta: { role: '/followUpStatistics' }
			},
			{
				path:'/pukouSevereMental',
				name:'浦口区重精管理',
				component:() => import("@/views/console/severeMental/pukouSevereMental.vue"),
				meta: { role: '/pukouSevereMental' }
			},
			{
				path:'/hotlineManage',
				name:'热线管理',
				component:()=>import("@/views/console/counselorManage/hotlineManage.vue"),
				meta: { role: '/hotlinemanage' }
			},
			{
				path:'/appointManage',
				name:'预约咨询管理',
				component:()=>import("@/views/console/counselorManage/appointManage.vue"),
				meta: { role: '/appointmanage' }
			},
			{
				path:'/counselorSummary',
				name:'咨询师自总结查询',
				component:()=>import("@/views/console/counselorManage/counselorSummary.vue"),
				meta: { role: '/counselorsummary' }
			},
			{
				path:'/aptitudeAudit',
				name:'资质认证待审核',
				component:()=>import("@/views/console/aptitudeManage/aptitudeAudit.vue"),
				meta: { role: '/aptitudeaudit' }
			},
			{
				path:'/aptitudeApproved',
				name:'资质认证已审核',
				component:()=>import("@/views/console/aptitudeManage/aptitudeApproved.vue"),
				meta: { role: '/aptitudeapproved' }
			},
			{
				path:'/personalInformation',
				name:'个人信息',
				component:()=>import("@/views/console/userManage/personalInformation.vue"),
				meta: { role: '/personalinformation' }
			},
			{
				path:'/counselorInformation',
				name:'咨询师信息',
				component:()=>import("@/views/console/userManage/counselorInformation.vue"),
				meta: { role: '/counselorinformation' }
			},
			{
				path:'/teamUser',
				name:'监测名单明细',
				component:()=>import("@/views/console/userManage/teamUser.vue"),
				meta: { role: '/teamUser' }
			},
			{
				path:'/regionConfig',
				name:'区域配置',
				component:()=>import("@/views/console/belongManage/regionConfig.vue"),
				meta: { role: '/regionconfig' }
			},
			{
				path:'/organizeConfig',
				name:'机构配置',
				component:()=>import("@/views/console/belongManage/organizeConfig.vue"),
				meta: { role: '/organizeconfig' }
			},
			{
				path:'/dicManage',
				name:'字典管理',
				component:()=>import("@/views/console/dicManage/index.vue"),
				meta: { role: '/dicmanage' }
			},
			{
				path:'/careLevel',
				name:'关怀等级配置',
				component:()=>import("@/views/console/groupTestManage/careLevel.vue"),
				meta: { role: '/careLevel' }
			},
			{
				path:'/taskPackage',
				name:'任务量表套餐',
				component:()=>import("@/views/console/groupTestManage/taskPackage.vue"),
				meta: { role: '/taskPackage' }
			},
			{
				path:'/packageDistribution',
				name:'量表套餐分配',
				component:()=>import("@/views/console/groupTestManage/packageDistribution.vue"),
				meta: { role: '/packageDistribution' }
			},
			{
				path:'/testTask',
				name:'团测任务管理',
				component:()=>import("@/views/console/groupTestManage/testTask.vue"),
				meta: { role: '/testTask' }
			},
			{
				path:'/taskTestList',
				name:'任务测评名单',
				component:()=>import("@/views/console/groupTestManage/taskTestList.vue"),
				meta: { role: '/taskTestList' }
			},
			{
				path:'/taskAnalysis',
				name:'任务数据分析',
				component:()=>import("@/views/console/groupTestManage/taskAnalysis.vue"),
				meta: { role: '/taskAnalysis' }
			},
			{
				path:'/taskReport',
				name:'团测任务报告',
				component:()=>import("@/views/console/groupTestManage/taskReport.vue"),
				meta: { role: '/taskReport' }
			},
		]
	}
]

export default basicRoutes
