import { defineStore } from 'pinia'

export const useTabsStore = defineStore('tabs', {
  state: () => ({
    tabs: [],
    activeKey: '/generalView',
    selectedKeys: ['/generalView']
  }),
  actions: {
    clearTab() {
      this.tabs = []
    },
    addTab(tab) {
      this.tabs.push(tab)
    },
    removeTab(tab) {
      const index = this.tabs.indexOf(tab)
      if (index !== -1)
        this.tabs.splice(index, 1)
    },
    _tabs() {
      return this.tabs
    },
    setActiveKey(activeKey) {
      this.activeKey = activeKey
    },
    setSelectedKeys(key) {
      this.selectedKeys[0] = key
    },
    _activeKey() {
      return this.activeKey
    },
  },
})
