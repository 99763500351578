import CurdBase from './common/crudBase'

const curd = new CurdBase('/area')

/**
 * @description 获取区域配置列表
 * @returns ignore
 */
export function list(data) {
  return curd.basePost('/list', data)
}

/**
 * @description 首层区域树查询
 * @returns ignore
 */
export function getFirstArea() {
  return curd.basePost('/getFirstArea')
}

/**
 * @description 查询子区域列表
 * @returns ignore
 */
export function getAreaTreeByPid(id) {
  return curd.basePost('/getAreaTreeByPid', { id })
}

/**
 * @description 区域配置删除校验
 * @returns ignore
 */
export function checkCanDelete(data) {
  return curd.basePost('/checkCanDelete', data)
}

/**
 * @description 删除区域配置
 * @returns ignore
 */
export function delArea(data) {
  return curd.basePost('/delete', data)
}

/**
 * @description 省区域列表查询
 * @returns ignore
 */
export function listProvince() {
  return curd.basePost('/listProvince')
}

/**
 * @description 市区域列表查询
 * @returns ignore
 */
export function listCity(data) {
  return curd.basePost('/listCity', data)
}

/**
 * @description 县区列表查询
 * @returns ignore
 */
export function listTown(data) {
  return curd.basePost('/listTown', data)
}
