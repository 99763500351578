import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { getOrgTree } from '@/api/organizeconfig.js';

import { toRefs, ref, computed, unref, onMounted } from 'vue';
export default {
  __name: 'index',
  props: {
    modelValue: {
      type: [String, Number, Array]
    }
  },
  emits: ['update:modelValue'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emits = __emit;
    const {
      modelValue
    } = toRefs(props);
    const orgTreeList = ref([]);
    const dataLoading = ref(true);
    const selectedValue = computed({
      get: () => {
        return unref(modelValue) ?? [];
      },
      set: newVal => {
        emits('update:modelValue', newVal);
      }
    });
    onMounted(() => {
      getData();
    });
    function getData() {
      // 获取区域树状结构列表
      getOrgTree().then(res => {
        if (res.code === '0') orgTreeList.value = res.data || [];else message.error(res.message);
      }).finally(() => dataLoading.value = false);
    }
    return (_ctx, _cache) => {
      const _component_a_cascader = _resolveComponent("a-cascader");
      return _openBlock(), _createBlock(_component_a_cascader, {
        value: _unref(selectedValue),
        "onUpdate:value": _cache[0] || (_cache[0] = $event => _isRef(selectedValue) ? selectedValue.value = $event : null),
        "show-search": "",
        "change-on-select": "",
        loading: _unref(dataLoading),
        options: _unref(orgTreeList),
        "field-names": {
          label: 'name',
          value: 'id',
          children: 'children'
        },
        placeholder: "请选择"
      }, null, 8, ["value", "loading", "options"]);
    };
  }
};