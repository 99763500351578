const modulesFiles = require.context('./modules', true, /\.js$/)

const asyncRoutes = modulesFiles.keys().reduce((modules, modulePath) => {
  const file = modulesFiles(modulePath)
  const value = file.default || []
  modules.push(...value)
  return modules
}, [])

export const routesChildren = asyncRoutes
