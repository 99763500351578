import path from 'path'

const componentFiles = require.context('./', true, /\.vue$/)

// 批量注册全局组件
function install(app) {
  componentFiles.keys().forEach((key) => {
    if (!path.basename(key, 'index.vue')) {
      const name = path.join(key, '..')
      const component = componentFiles(key).default
      app.component(name, component)
    }
  })
}

export default { install }
