import { defineStore } from 'pinia'
import piniaPersistConfig from './piniaPersist'

export const useUserStore = defineStore('user', {
  state: () => ({
    access_token: '',
    token_type: '',
    refresh_token: '',
    username: '',
    account: '',
    roles: {
      menuRoles: [],
      btnRoles: [],
    },
    orgName: ''
  }),
  getters: {},
  actions: {
    setToken(access_token, token_type, refresh_token) {
      this.access_token = access_token
      this.token_type = token_type
      this.refresh_token = refresh_token
    },
    setUserName(username, account) {
      this.username = username
      this.account = account
    },
    setOrgName(orgName) {
      this.orgName = orgName
    },
    setRoles(menuRoles, btnRoles) {
      this.roles.menuRoles = menuRoles
      this.roles.btnRoles = btnRoles
    },
    // 清除token，清除userInfo
    async clearAuthContent() {
      this.username = ''
      this.account = ''
      this.roles = {
        menuRoles: [],
        btnRoles: [],
      }
      this.access_token = ''
      this.token_type = ''
      this.refresh_token = ''
    },
    clearUser() {
      this.username = ''
    },
  },
  persist: piniaPersistConfig('user'),
})
