import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2ede2a20"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "overview-layout"
};
const _hoisted_2 = {
  class: "charts-box"
};
import qiunVueUcharts from '@qiun/vue-ucharts';

import { toRefs, computed, unref } from 'vue';
export default {
  __name: 'ShapedReport',
  props: {
    testingResultDimension: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const {
      testingResultDimension
    } = toRefs(props);
    const dimensionFirst = computed(() => {
      const {
        dimensionScoreList = []
      } = unref(testingResultDimension);
      return dimensionScoreList.length !== 0 ? dimensionScoreList[0] : {};
    });
    const dimensionNames = computed(() => {
      const {
        dimensionScoreList = []
      } = unref(testingResultDimension);
      return dimensionScoreList.map(item => {
        return item.dimensionName;
      });
    });
    const dimensionSeries = computed(() => {
      const {
        dimensionScoreList = []
      } = unref(testingResultDimension);
      const data = dimensionScoreList.map(item => {
        return item.score;
      });
      return {
        name: '实际分',
        data
      };
    });
    const dimensionNormSeries = computed(() => {
      const {
        dimensionScoreList = []
      } = unref(testingResultDimension);
      const data = dimensionScoreList.map(item => {
        return item.norm;
      });
      return {
        name: '群体均值',
        data
      };
    });

    // 左侧名称
    const leftNames = computed(() => {
      const {
        dimensionScoreList = []
      } = unref(testingResultDimension);
      return dimensionScoreList.map(item => {
        return item.leftBarName;
      });
    });

    // 右侧名称
    const rightNames = computed(() => {
      const {
        dimensionScoreList = []
      } = unref(testingResultDimension);
      return dimensionScoreList.map(item => {
        return item.rightBarName;
      });
    });
    const opts = computed(() => ({
      color: ['#1890FF', '#91CB74', '#FAC858', '#EE6666', '#73C0DE', '#3CA272', '#FC8452', '#9A60B4', '#ea7ccc'],
      padding: [15, 30, 0, 5],
      enableScroll: false,
      legend: {},
      xAxis: {
        disabled: true,
        boundaryGap: 'justify',
        disableGrid: true,
        min: unref(dimensionFirst).lowerLimit ?? 0,
        axisLine: false,
        max: unref(dimensionFirst).upperLimit ?? 0
      },
      yAxis: {},
      extra: {
        bar: {
          type: 'stack',
          width: 30,
          meterBorde: 1,
          meterFillColor: '#FFFFFF',
          activeBgColor: '#000000',
          activeBgOpacity: 0.08,
          categoryGap: 2
        }
      }
    }));
    const chartData = computed(() => {
      const res = {
        categories: unref(dimensionNames),
        leftNames: unref(leftNames),
        rightNames: unref(rightNames),
        series: [unref(dimensionSeries), unref(dimensionNormSeries)]
      };
      return JSON.parse(JSON.stringify(res));
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(qiunVueUcharts), {
        type: "bar",
        opts: _unref(opts),
        "chart-data": _unref(chartData)
      }, null, 8, ["opts", "chart-data"])])]);
    };
  }
};