import CurdBase from './common/crudBase'

const curd = new CurdBase('/')

/**
 * @description 导入通用
 * @returns ignore
 */
export function uploadFile(url, data) {
  return curd.baseFilePost(url, data)
}
