/**
 * @description pinia持久化参数配置
 * @param {String} key 存储到持久化的 name
 * @return persist
 * */
function piniaPersistConfig(key) {
  return {
    key,
    storage: window.localStorage,
  }
}

export default piniaPersistConfig
