// const context = process.env.NODE_ENV !== 'development' ? process.env.VUE_APP_BASE_URL : ''
const context = process.env.VUE_APP_BASE_URL && process.env.VUE_APP_BASE_URL !== '/' ? process.env.VUE_APP_BASE_URL : ''
const defaultConfig = {
  // 默认值
  clientId: 'agile',
  clientSecret: '123456',
  app_code: 'suxin-server',
  appHost: 'oauth',
  tokenHost: 'oauth/token',
  checkTokenHost: 'oauth/check_token',
  revokeHost: 'oauth/removeToken',
  redirectUri: `${window.location.protocol}//${window.location.host}`,
  revokeUri: `${window.location.protocol}//${window.location.host}${context}/`,
  grantType: 'password',
}

export const config = { ...defaultConfig }
