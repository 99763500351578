import CurdBase from './common/crudBase'

const curd = new CurdBase('/org')

/**
 * @description 获取机构配置
 * @returns ignore
 */
export function list(data) {
  return curd.basePost('/list', data)
}

/**
 * @description 获取机构树状结构列表
 * @returns ignore
 */
export function getOrgTree(data) {
  return curd.basePost('/getOrgTree', data)
}

/**
 * @description 组织机构树查询（机构名称为空时，只返回第一层）
 * @returns ignore
 */
export function getOrgTreeList(orgName = '') {
  return curd.basePost('/getOrgTreeList', { orgName })
}

/**
 * @description 查询子机构列表
 * @returns ignore
 */
export function getOrgTreeByPid(id) {
  return curd.basePost('/getOrgTreeByPid', { id })
}

/**
 * @description 新增机构配置
 * @returns ignore
 */
export function add(data) {
  return curd.basePost('/add', data)
}

/**
 * @description 修改机构配置
 * @returns ignore
 */
export function update(data) {
  return curd.basePost('/update', data)
}

/**
 * @description 机构配置删除校验
 * @returns ignore
 */
export function checkCanDelete(data) {
  return curd.basePost('/checkCanDelete', data)
}

/**
 * @description 删除机构配置列表
 * @returns ignore
 */
export function delOrganize(data) {
  return curd.basePost('/delete', data)
}

/**
 * @description 获取机构列表
 * @returns ignore
 */
export function getOrgList() {
  return curd.basePost('/orgList')
}

/**
 * @description 获取机构列表
 * @returns ignore
 */
export function getOrgTreeByTaskId(id) {
  return curd.basePost('/getOrgTreeByTaskId', { id })
}
