import { request, splitJointUrl } from '@/utils/request'

const urlprefix = process.env.VUE_APP_AUTH_URL

/**
 * @description 获取token
 * @param url
 * @returns
 */
export function getAuthToken(urlParam) {
  const url = splitJointUrl(urlprefix, urlParam)
  return request({
    url,
    method: 'get',
  }, {
    isAuthToken: false,
  })
}

/**
 * @description 登出
 * @param url
 * @returns
 */
export function revoke(urlParam) {
  const url = splitJointUrl(urlprefix, urlParam)
  return request({
    url,
    method: 'get',
  })
}

/**
 * @description 获取用户信息
 * @returns
 */
export function getUserInfoApi() {
  return request({
    url: `${urlprefix}/user/info`,
    method: 'get',
  })
}

/**
 * @description 获取用户权限
 * @param appCode
 * @returns
 */
export function getUserPermission(appCode) {
  return request({
    url: `${urlprefix}/user/userPermission?app_code=${appCode}`,
    method: 'get',
  })
}

/**
 * @description 校验token
 * @param url
 * @returns
 */
export function checkToken(urlParam) {
  const url = splitJointUrl(urlprefix, urlParam)
  return request({
    url,
    method: 'get',
  })
}

/**
 * @description 重置密码
 * @param data
 * @returns
 */
export function changePsw(data) {
  return request({
    url: `${urlprefix}/user/changePassword`,
    method: 'post',
    data,
  })
}
