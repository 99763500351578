import { initGlobalState, registerMicroApps, start } from 'qiankun'
import { getCurrentInstance } from 'vue'
import { registrableApps } from './apps.config'

let action = null

const microApps = process.env.NODE_ENV === 'development' ? registrableApps : window.qiankunApps

class GlobleStore {
  constructor() {}
  get() {
    const authString = localStorage.getItem('user')
    return authString ? JSON.parse(authString).access_token : null
  }

  dispatch() {
    getCurrentInstance()?.proxy.logout()
  }
}

const globleStore = new GlobleStore()
class App {
  constructor(config) {
    const { appName = '', entry = '', activeRule, id } = config
    this.appId = id
    this.name = appName
    this.entry = entry
    this.activeRule = activeRule || `/${appName}`
    this.container = '#subapp-viewport'
    this.props = {
      access_token: '',
      activeRule: activeRule || `/${appName}`,
      container: '#subapp-viewport',
      globleStore,
      headHeight: 43,
      theme: 'default',
    }
  }
}

const apps = microApps.reduce((accumulator, currentValue) => {
  if (currentValue.appName !== 'ROOT') {
    const app = new App(currentValue)
    accumulator.push(app)
  }
  return accumulator
}, [])

registerMicroApps(apps, {
  beforeLoad: [
    (app) => {
      console.log('[LifeCycle] before load %c%s', 'color: green;', app.name)
    },
  ],
  beforeMount: [
    (app) => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    },
  ],
  afterUnmount: [
    (app) => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
    },
  ],
})
action = initGlobalState({ theme: 'default' })

window.__POWERED_BY_QIANKUN__ = true

setTimeout(() => {
  start({
    // sandbox: {
    //   strictStyleIsolation : true,
    //   experimentalStyleIsolation: true
    // }
  })
}, 1000)
