import axios from 'axios'
import CurdBase from './common/crudBase'

const curd = new CurdBase()

/**
 * 文件上传接口
 * @param {*} data
 * @returns
 */
export function uploadRequest(data) {
  return curd.post(`${process.env.VUE_APP_API_FILE_URL}/file/upload`, data)
}

export function downLocalFile(name, fileName = '文件导出') {
  return new Promise((resolve) => {
    axios.get(`files/${name}`, { // 静态资源文件夹public
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      resolve()
    }).catch((error) => {
      console.log(`error:${JSON.stringify(error)}`)
      resolve()
    })
  })
}
