import { request } from '@/utils/request'

class CurdBase {
  url = ''
  baseUrl = process.env.VUE_APP_API_URL

  constructor(url) {
    if (!url)
      return
    if (!String.prototype.startsWith.call(url, '/'))
      url = `/${url}`

    this.url = `${this.baseUrl}${url}`
  }

  baseRequest(options) {
    return request(options)
  }

  basePost(url, data = {}, responseType) {
    return request({
      url: this.url + url,
      method: 'POST',
      data,
      responseType,
    })
  }

  // 文件上传参数
  baseFilePost(url, data = {}, responseType) {
    return request({
      url: this.url + url,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
      responseType,
    })
  }

  baseUrlPost(url, data = {}, responseType) {
    return request({
      url: `${this.baseUrl}${url}`,
      method: 'POST',
      data,
      responseType,
    })
  }

  post(url, data = {}, responseType) {
    return request({
      url,
      method: 'POST',
      data,
      responseType,
    })
  }

  baseGet(url, params = {}, responseType) {
    return request({
      url: this.url + url,
      method: 'GET',
      params,
      responseType,
    })
  }

  search(data = {}) {
    return this.basePost(`${this.url}/search`, data)
  }

  add(data) {
    return this.basePost(`${this.url}/add`, data)
  }

  create(data) {
    return this.basePost(`${this.url}/create`, data)
  }

  update(data) {
    return this.basePost(`${this.url}/update`, data)
  }

  delete(data) {
    return this.basePost(`${this.url}/delete`, data)
  }
}

export default CurdBase
