import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPlugin from 'pinia-plugin-persistedstate'
import router from './router'
import App from './App.vue'
import eaComponents from './components/index'
import authPlugin from './plugins/authPlugin/index'

// 富文本组件
import Tinymce from './components/Tinymce/tinymce'
import '@/assets/iconfont/iconfont.css'

import '@/styles/index.scss' // 系统自定义样式
import '@/qiankun/master'

const pinia = createPinia().use(piniaPlugin)

const app = createApp(App)
app.use(router).use(pinia).use(eaComponents).use(Tinymce).mount('#portal')

authPlugin.install(app)

export default app
