import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  __name: 'App',
  setup(__props) {
    const locale = zhCN;
    // 表格文本格式化空为'-'
    const transformCellText = function ({
      text,
      column,
      record,
      index
    }) {
      return !text || text.length === 0 ? '-' : text;
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_a_config_provider = _resolveComponent("a-config-provider");
      return _openBlock(), _createBlock(_component_a_config_provider, {
        locale: _unref(locale),
        "component-size": "middle",
        "transform-cell-text": transformCellText,
        theme: {
          token: {
            colorPrimary: '#31A994',
            colorTextBase: '#4D5C76',
            colorText: '#2D3F5E',
            borderRadius: 4,
            colorInfo: '#31A994',
            colorBgLayout: '#F3F7FB',
            layoutHeaderHeight: '50px',
            layoutHeaderColor: '#ffffff',
            colorBorder: '#EBEEF0'
          }
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};