import { defineStore } from 'pinia'
import piniaPersistConfig from './piniaPersist'

export const useAppStore = defineStore('app', {
  state: () => ({
    appLoading: false,
  }),
  getters: {},
  actions: {
    setAppLoading(appLoading) {
      this.appLoading = appLoading
    },
  },
  persist: piniaPersistConfig('app'),
})
