import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import { routesChildren } from './routes'
import Vue from '@/main'
import Layout from '@/layout/index.vue'

// progress bar
import 'nprogress/nprogress.css'

// 引入nprogress的css
NProgress.configure({ showSpinner: false }) // NProgress Configuration

//
const defaultRedirectUrl = '/home'

const routes = [
  {
    path: '/',
    component: Layout,
    children: routesChildren,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (to.path === '/noPermission')
    next()

  const { meta } = to
  const role = meta.role ?? ''
  if (!(role === 'all' || Vue.config.globalProperties.$hasRole(role, '2')))
    next({ path: '/noPermission' })

  const user = localStorage.getItem('user')
  const userObj = user ? JSON.parse(user) : {}
  const { access_token } = userObj
  if (to.path === '/login') {
    if (!access_token) {
      localStorage.removeItem('user')
      next()
      return
    }
    else {
      try {
        await Vue.config.globalProperties.checkTokenByAccessToken()
        next({
          path: defaultRedirectUrl,
        })
        return
      }
      catch (error) {
        localStorage.removeItem('user')
        next()
        return
      }
    }
  }

  // 没有token的时候, 直接跳回登录页
  if (!access_token) {
    next({
      path: '/login',
    })
    return
  }

  try {
    if (userStore.roles.menuRoles.length === 0) {
      Vue.config.globalProperties.getUserInfoByAccessToken()
      Vue.config.globalProperties.getUserPermissionByAccessToken()
    }
    next()
  }
  catch (error) {
    next()
  }
})

router.afterEach((to, from) => {
  NProgress.done() // finish progress bar
})

router.onError(() => {
  NProgress.done() // finish progress bar
})

export default router
