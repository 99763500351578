import axios from 'axios'
import { message as $message } from 'ant-design-vue'
import { uniqueSlash } from './index'
import { useUserStore } from '@/stores'

// export interface RequestOptions {
//   /** 当前接口权限, 不需要鉴权的接口请忽略， 格式：sys:user:add */
//   permCode?: string;
//   /** 是否直接获取data，而忽略message等 */
//   isGetDataDirectly?: boolean;
//   /** 请求成功是提示信息 */
//   successMsg?: string;
//   /** 请求失败是提示信息 */
//   errorMsg?: string;
//   /** 是否mock数据请求 */
//   isMock?: boolean;
//   /** 是否需要token */
//   isAuthToken?: boolean;
// }

const ERROR_CONTENT = {
  UNKNOWN_ERROR: '未知错误，请重试',
}
const ERROR_CODE = {
  UNAUTHORIZED: 401,
}

// 题目配置量比较大，超时改成了2分钟
const service = axios.create({
  // baseURL: baseApiUrl,
  timeout: 1000 * 60 * 2,
})

service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

service.interceptors.response.use(
  (response) => {
    // const res = response.data;

    // if the custom code is not 200, it is judged as an error.
    // if (res.code !== 200) {
    //   $message.error(res.message || ERROR_CONTENT.UNKNOWN_ERROR);

    //   // Illegal token
    //   if (res.code === 11001 || res.code === 11002) {
    //     window.localStorage.clear();
    //     window.location.reload();
    //   }

    //   // throw other
    //   const error = new Error(res.message || ERROR_CONTENT.UNKNOWN_ERROR)
    //   error.code = res.code;
    //   return Promise.reject(error);
    // } else {
    return response
    // }
  },
  (error) => {
    if (error.response.status === ERROR_CODE.UNAUTHORIZED)
      location.href = `${process.env.VUE_APP_BASE_URL}/login`

    // 处理 422 或者 500 的错误异常提示
    const errMsg = error.response.data.message ?? ERROR_CONTENT.UNKNOWN_ERROR
    $message.error({ content: errMsg, key: errMsg })
    error.message = errMsg
    return Promise.reject(error)
  },
)

/**
 *
 * @param method - request methods
 * @param url - request url
 * @param data - request data or params
 */
export async function request(config, options = {}) {
  try {
    const userStore = useUserStore()
    const { isGetDataDirectly = true, isAuthToken = true } = options
    config.url = uniqueSlash(config.url)

    if (isAuthToken && userStore.access_token) {
      config.headers = {
        Authorization: `Bearer ${userStore.access_token}`,
      }
    }

    const res = await service.request(config)
    return isGetDataDirectly ? res.data : res
  }
  catch (error) {
    return Promise.reject(error)
  }
}

export function download(downloadUrl, data, filename = '下载数据', useImageUrl = false, type, requestType = 'post') {
  if (type === null || type === undefined)
    type = 'application/vnd.ms-excel,charset=utf-8'
    
    const userStore = useUserStore()
    const url = useImageUrl ? process.env.VUE_APP_IMAGE_URL + downloadUrl : process.env.VUE_APP_API_URL + downloadUrl
    return axios({
      method: requestType,
      url,
      data,
      headers: { Authorization: `Bearer ${userStore.access_token}` },
      responseType: 'arraybuffer',
    }).then((res) => {
      let errorObject = null
      let isFaild = false
      const enc = new TextDecoder('utf-8')
      const errorMessage = enc.decode(new Uint8Array(res.data))
      if (errorMessage.includes('message')) {
        errorObject = JSON.parse(errorMessage)
        isFaild = true
      }
      if (isFaild) {
        $message.error(`下载失败[${errorObject.message}]`)
      }
      else {
        const blob = new Blob([res.data], { type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      }
    })
}

export function splitJointUrl(baseUrl, url) {
  if (url)
    return `${baseUrl}/${url}`
  return baseUrl
}
