import { message } from 'ant-design-vue'

/**
 * 将路径中重复的正斜杆替换成单个斜杆隔开的字符串
 * @param path 要处理的路径
 * @returns {string} 将/去重后的结果
 */
export const uniqueSlash = path => path.replace(/(https?:\/)|(\/)+/g, '$1$2')

/**
 * @description 图片上传之前的校验
 * @param {*} file
 * @returns
 */
export function beforeImageUpload(file) {
  return new Promise((resolve, reject) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng)
      message.error('图片格式只支持jpeg和png格式!')

    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M)
      message.error('图片大小不能超过 2MB!')

    if (!isJpgOrPng || !isLt2M)
      reject()
    else
      resolve()
  })
}

/**
 * @description 转换base64
 * @param {*} img
 * @param {*} callback
 */
export function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

/**
 * @description 图片默认地址appUrlHost
 * @param {*} img
 * @param {*} callback
 */
export function appUrlHost(url) {
  return url && url.includes('base64') ? url : `${process.env.VUE_APP_IMAGE_URL}${url}`
}

/**
 * 字母排列
 * @param {*} index
 * @returns
 */
export function alphabeticalArrangement(index) {
  const keyIndex = Math.floor(index / 26)
  const newIndex = index - 26 * keyIndex
  const code = String.fromCharCode('A'.charCodeAt(0) + newIndex)
  return `${code}${keyIndex === 0 ? '' : keyIndex}`
}

/**
 * 获取树级结构第一个最底层孩子节点
 * @param {*} data
 * @param {*} list
 * @returns
 */
export function getTreeFirstChildren(data, list) {
  if (data && data.length > 0) {
    if (data[0].children && data[0].children.length > 0)
      getTreeFirstChildren(data[0].children, list)

    list[0].unshift(data[0].id)
    list[1].unshift(data[0].name)
  }
  return list
}
