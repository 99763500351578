import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { getOrgTreeList, getOrgTreeByPid } from '@/api/organizeconfig.js';
import { getFirstArea, getAreaTreeByPid } from '@/api/regionconfig.js';
import { getOrgTreeByTaskId } from '@/api/organizeconfig.js';
import { message } from 'ant-design-vue';

import { toRefs, ref, onMounted, unref, watch } from 'vue';
export default {
  __name: 'index',
  props: {
    modelValue: {
      type: [String, Number, Array]
    },
    isMultiple: {
      type: Boolean,
      default: () => false
    },
    // type值有3个：org 为机构、zone 为地区、orgWithId 为根据id请求机构数据
    type: {
      type: String,
      default: () => 'org'
    },
    taskId: {
      type: String,
      default: () => ''
    },
    parentOrg: {
      type: [Array, Object]
    },
    onlySelectLastChild: {
      type: Boolean
    }
  },
  emits: ['update:modelValue', 'initOrg', 'orgNameChange'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const {
      modelValue,
      type,
      taskId,
      parentOrg,
      onlySelectLastChild
    } = toRefs(props);
    const emits = __emit;
    const treeData = ref([]);
    const allTreeData = ref([]);
    const isTreeDataSimpleMode = ref(true);
    const hasGetFirstTreeNode = ref(false);
    const map = new Map();
    onMounted(() => {
      getFirstTreeNode();
    });

    // 获取首层树
    const getFirstTreeNode = async () => {
      try {
        let res = {};
        isTreeDataSimpleMode.value = true;
        if (unref(type) === 'org') {
          res = await getOrgTreeList();
        } else if (unref(type) === 'zone') {
          res = await getFirstArea();
        } else if (unref(type) === 'orgWithId') {
          res = await getOrgTreeByTaskId(unref(taskId));
        }
        if (res.code === '0') {
          if (unref(type) !== 'zone' && res.data) {
            res.data.forEach(item => {
              if (item.children && item.children.length > 0) {
                isTreeDataSimpleMode.value = false;
              }
            });
          }
          if (!isTreeDataSimpleMode.value) {
            allTreeData.value = res.data || [];
          } else {
            treeData.value = (res.data || []).map(item => {
              const obj = {
                pId: 0,
                id: item.id,
                value: item.id,
                title: item.name,
                label: item.name,
                isLeaf: item.childNum <= 0,
                isRequireChild: false
              };
              if (onlySelectLastChild.value) {
                obj.selectable = item.childNum <= 0;
              }
              return obj;
            });
          }
          if (unref(type) === 'orgWithId') {
            if (!isTreeDataSimpleMode.value) {
              emits('initOrg', allTreeData.value[0] || []);
            } else {
              emits('initOrg', treeData.value[0] || []);
            }
          }
          hasGetFirstTreeNode.value = true;
        } else {
          message.error(res.message);
        }
      } catch (error) {
        message.error(error);
      }
    };

    // 获取子层树
    const getChildTreeNode = async (parentId, parentLabel, isLeaf = false, isRequireChild = false) => {
      try {
        if (!isLeaf && !isRequireChild) {
          let res = {};
          if (unref(type) === 'org' || unref(type) === 'orgWithId') {
            res = await getOrgTreeByPid(parentId);
          } else if (unref(type) === 'zone') {
            res = await getAreaTreeByPid(parentId);
          }
          if (res.code === '0') {
            const childrenData = (res.data || []).map(item => {
              const obj = {
                pId: parentId,
                id: item.id,
                value: item.id,
                title: item.name,
                label: `${parentLabel}>${item.name}`,
                isLeaf: item.childNum <= 0,
                isRequireChild: false
              };
              if (onlySelectLastChild.value) {
                obj.selectable = item.childNum <= 0;
              }
              return obj;
            });
            return childrenData;
          } else {
            message.error(res.message);
          }
        }
      } catch (error) {
        message.error(error);
      }
    };

    // 点击节点请求子层树
    const onLoadData = treeNode => {
      return new Promise(resolve => {
        const {
          id,
          label,
          isLeaf,
          isRequireChild
        } = treeNode.dataRef;
        setTimeout(async () => {
          // treeData.value = treeData.value.filter((item) => item.pId !== id)
          const res = await getChildTreeNode(id, label, isLeaf, isRequireChild);
          if (res) {
            treeData.value = treeData.value.map(item => {
              return item.id === id ? {
                ...item,
                isRequireChild: true
              } : {
                ...item
              };
            });
            treeData.value = treeData.value.concat(res);
          }
          resolve(true);
        }, 300);
      });
    };
    function handlerChange(value, label) {
      emits('update:modelValue', value);
      emits('orgNameChange', label);
    }
    const setTreeData = async newVal => {
      if (isTreeDataSimpleMode.value) {
        for (let i = newVal.length - 1; i > 0; i--) {
          if (!map.has(newVal[i].id)) {
            map.set(newVal[i].id, newVal[i]);
            const arr = treeData.value.filter(item => item.id === newVal[i].id);
            if (arr.length > 0) {
              const {
                id,
                label,
                isLeaf,
                isRequireChild
              } = arr[0];
              treeData.value = treeData.value.filter(item => item.pId !== id);
              const res = await getChildTreeNode(id, label, isLeaf, isRequireChild);
              if (res) {
                treeData.value = treeData.value.map(item => {
                  return item.id === id ? {
                    ...item,
                    isRequireChild: true
                  } : {
                    ...item
                  };
                });
                treeData.value = treeData.value.concat(res);
              }
            }
          }
          // // 这种做法可以不请求就设置编辑的回显，但是会出现如果子级选择了，直接父级也选择了，直接父级没有显示问题
          // if (i === newVal.length - 1) {
          // 	newVal[i].label = newVal[i].orgName
          // } else {
          // 	newVal[i].label = newVal[i + 1].label + '>' + newVal[i].orgName
          // 	treeData.value.push({
          // 		pId: newVal[i+1].id,
          // 		id: newVal[i].id,
          // 		value: newVal[i].id,
          // 		title: newVal[i].orgName,
          // 		label: newVal[i].label,
          // 		isLeaf: true
          // 	})
          // }
        }
      }
    };
    watch(() => [parentOrg.value, hasGetFirstTreeNode.value], ([newVal, newVal2]) => {
      if (newVal && newVal2) {
        if (Object.prototype.toString.call(newVal) === '[object Array]') {
          if (newVal.length > 0) {
            setTreeData(newVal);
          }
        } else if (Object.prototype.toString.call(newVal) === '[object Object]') {
          Object.values(newVal).forEach(item => {
            if (item.length > 1) {
              setTreeData(item);
            }
          });
        }
      }
    });
    return (_ctx, _cache) => {
      const _component_a_tree_select = _resolveComponent("a-tree-select");
      return _unref(isTreeDataSimpleMode) ? (_openBlock(), _createBlock(_component_a_tree_select, _mergeProps({
        key: 0,
        value: _unref(modelValue),
        "tree-node-label-prop": "label"
      }, _ctx.$attrs, {
        "allow-clear": "",
        multiple: __props.isMultiple,
        style: {
          "width": "100%"
        },
        "tree-data-simple-mode": "",
        "dropdown-style": {
          maxHeight: '400px',
          overflow: 'auto'
        },
        "tree-data": _unref(treeData),
        placeholder: "请选择",
        "load-data": onLoadData,
        onChange: handlerChange
      }), null, 16, ["value", "multiple", "tree-data"])) : (_openBlock(), _createBlock(_component_a_tree_select, _mergeProps({
        key: 1,
        value: _unref(modelValue)
      }, _ctx.$attrs, {
        "allow-clear": "",
        multiple: __props.isMultiple,
        style: {
          "width": "100%"
        },
        "dropdown-style": {
          maxHeight: '400px',
          overflow: 'auto'
        },
        "tree-data": _unref(allTreeData),
        placeholder: "请选择",
        fieldNames: {
          label: 'name',
          value: 'id'
        },
        onChange: handlerChange
      }), null, 16, ["value", "multiple", "tree-data"]));
    };
  }
};