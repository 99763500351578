const system = [
  {
    name: '系统管理',
    path: '/admin',
    meta: {
      layout: 'HeaderLayout',
      qiankun: true,
    },
  },
]

export default system
