import { config } from './config'
import { useUserStore } from '@/stores'
import { checkToken, getAuthToken, getUserInfoApi, getUserPermission, revoke } from '@/api/auth'
import { getOrgList } from '@/api/organizeconfig'

const AUTH_TYPE = {
  MENU: '2',
  BUTTON: '3',
}

function install(Vue) {
  const userStore = useUserStore()

  /**
	 * @description 通过用户和密码获取token
	 * @param username
	 * @param password
	 * @returns
	 */
  Vue.config.globalProperties.getAccessTokenByPwd = async (username, password) => {
    try {
      await userStore.clearAuthContent()
      const url = `${config.tokenHost}?username=${username}&password=${password}&grant_type=${config.grantType}&client_id=${config.clientId}&client_secret=${config.clientSecret}`
      const res = await getAuthToken(url)
      userStore.setToken(res.access_token, res.token_type, res.refresh_token)
    }
    catch (error) {
      console.log(error)
      throw new Error(error.message || '获取token出错了')
    }
  }

  /**
	 * @description 校验token
	 * @returns
	 */
  Vue.config.globalProperties.checkTokenByAccessToken = () => {
    const url = `${config.checkTokenHost}?token=${userStore.access_token}`
    return checkToken(url)
  }

  /**
	 * @description 根据token获取用户信息
	 * @returns
	 */
  Vue.config.globalProperties.getUserInfoByAccessToken = async () => {
    try {
      if (userStore.username)
        return userStore.username
      const userRes = await getUserInfoApi()
      if (userRes.code === 0) {
        const { name = '', account = '', orgId = '' } = userRes.data
        userStore.setUserName(name, account)
        getOrgList().then((res) => {
          if (res.code == 0) {
            const orgName = (res.data || []).filter(item => item.orgId === orgId)
            if (orgName.length > 0) {
              userStore.setOrgName(orgName[0].orgName)
            }
          }
        })
      }
      else {
        Vue.prototype.$message.error(userRes.msg)
      }
    }
    catch (error) {
      throw new Error('暂无token或token已失效，请先进行登录获取token')
    }
  }

  /**
	 * @description 根据token获取用户权限列表
	 * @returns
	 */
  Vue.config.globalProperties.getUserPermissionByAccessToken = async () => {
    if (userStore.roles.menuRoles.length !== 0)
      return

    try {
      const menuRoles = []
      const btnRoles = []
      const res = await getUserPermission(config.app_code)
      if (res.code === 0) {
        res.data.permissions.forEach((element) => {
          if (element.type === AUTH_TYPE.MENU)
            menuRoles.push(element.permission_code)
					 else
            btnRoles.push(element.permission_code)
        })
        userStore.setRoles(menuRoles, btnRoles)
      }
    }
    catch (error) {
      console.log(error)
      throw new Error('获取菜单权限出错了')
    }
  }

  /**
	 * @description 登出
	 * @returns
	 */
  Vue.config.globalProperties.logout = async () => {
    try {
      await revoke(config.revokeHost)
      userStore.clearAuthContent()
      if (window.location.href !== config.revokeUri)
        window.location.href = config.revokeUri
    }
    catch (error) {
      console.log('logout revoke error', error)
    }
  }

  /**
	 * @description 跳转到登录页
	 */
  Vue.config.globalProperties.toLoginPage = () => {
    window.location.href = config.revokeUri
  }

  /**
	 * 权限点判断
	 * @param {*} authority_point
	 * @param {*} authority_type
	 * @returns true 有权限或不需要权限 false 无权限
	 */
  Vue.config.globalProperties.$hasRole = (authority_point, authority_type = 'ALL') => {
    if (!authority_point || authority_point === '')
      return true
    const roles = authority_type === 'ALL'
      ? [...userStore.roles.menuRoles, ...userStore.roles.btnRoles]
      : authority_type === AUTH_TYPE.MENU ? userStore.roles.menuRoles : userStore.roles.btnRoles
    if (roles.includes(authority_point))
      return true

    return false
  }
}

export default { install }
